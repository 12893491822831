<template>
  <!-- 用户管理 -->
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div>
          <el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450" placeholder="请输入"
            v-model="retrieveForm.searchValue">
            <el-button slot="append" type="primary" icon="el-icon-search" @click="submitSearch"></el-button>
          </el-input>
        </div>
        <div class="flex flex-ac">
          <div class="fs-16 fc-6b9eff pointer" @click="addList">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
          </div>
        </div>
      </div>
      <div class="retrieveDiv flex flex-ac">
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20">用户名：</span>
          <el-input class="w-250" v-model="retrieveForm.userName"></el-input>
        </div>
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20">手机号：</span>
          <el-input class="w-250" v-model="retrieveForm.phone"></el-input>
        </div>
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20">邮箱：</span>
          <el-input class="w-250" v-model="retrieveForm.email"></el-input>
        </div>
        <div label="">
          <el-button type="primary" @click="submitSearch">查询</el-button>
        </div>
      </div>
    </div>
    <TableHeightFull class="pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableList" />
    </TableHeightFull>
    <!-- 详情弹框 -->
    <admin-details ref="admin-details" @callBack="getTableList"></admin-details>
    <!-- 账户弹框 -->
    <el-dialog title="新增账户" :visible.sync="dialogFormVisible" width="30%" @close="resetForm">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules">
        <el-form-item label="账户名称" prop="userName" v-if="'userName' in ruleForm" label-width="120px">
          <el-input type="text" v-model="ruleForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone" v-if="'phone' in ruleForm" label-width="120px">
          <el-input type="text" v-model="ruleForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password" v-if="'password' in ruleForm" label-width="120px">
          <el-input type="password" v-model="ruleForm.password" autocomplete="new-password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="newPassword" v-if="'newPassword' in ruleForm" label-width="120px">
          <el-input type="password" v-model="ruleForm.newPassword" autocomplete="new-password"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email" v-if="'email' in ruleForm" label-width="120px">
          <el-input type="text" v-model="ruleForm.email"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="AddEditRole" :loading="butLoading">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 分配角色 -->
    <el-dialog title="配置角色" :visible.sync="dialogRole" width="30%">
      <div class="mv-40 h-250 overflow-scroll-y">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="roleIds" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="item in roleData" :label="item.id" :key="item.id" class="mb-10">{{item.role}}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogRole = false">取 消</el-button>
        <el-button type="primary" @click="userRole" :loading="butLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
export default {
  name: 'user',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "CountDown": (resolve) => require(["@/components/CountDown"], resolve),
    "admin-details": (resolve) => require(["@/views/modules/systemAdmin/adminDetails"], resolve),
  },

  data () {
    const checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/
      if (!reg.test(value)) {
        callback(new Error('请输入11位手机号'))
      } else {
        callback()
      }
    }
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      retrieveForm: {
        searchValue: '',
        userName: '',
        phone: '',
        email: ''
      },
      butLoading: false, //按钮
      tabLoading: false, //表单
      columns: [
        { type: 'index', label: '序号', width: '60', align: 'center', fixed: 'left', index: this.indexMethod },
        { label: '用户名', prop: 'userName', align: 'left', 'show-overflow-tooltip': true },
        { label: '手机号', prop: 'phone', align: 'left', 'show-overflow-tooltip': true },
        {
          label: '角色', prop: 'roles', align: 'left', 'show-overflow-tooltip': true, formatData: (e) => {
            if (e) {
              let roleName = e.map(ele => { return ele.role })
              return roleName.join(',')
            } else {
              return ''
            }
          }
        },
        { label: '邮箱', prop: 'email', align: 'left', 'show-overflow-tooltip': true },
        { label: '创建时间', prop: 'createDate', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '230',
          buttons: [
            { type: 'text', text: '删除', fn: this.deleteList, },
            { type: 'text', text: '编辑', fn: this.editList, },
            { type: 'text', text: '修改密码', fn: this.editPassword, },
            { type: 'text', text: '分配角色', fn: this.allocation }
          ],
        },
      ],
      tableData: [],
      // 分配角色弹框
      dialogRole: false,
      roleData: [],// 所有角色
      userId: '',//选择用户id
      checkAll: false, //全选
      isIndeterminate: false, //不确定状态
      roleIds: [],  //选择的角色id
      //新增 修改弹框
      ruleType: 1,//1新增 2编辑 3修改
      dialogFormVisible: false,
      ruleForm: {
        userName: '', // *用户账号
        phone: '', // *手机号
        password: '', // *用户密码
        newPassword: '',// *用户确认密码
        email: '', // *邮箱
      },
      rules: {
        userName: [{ required: true, message: '账户名称不能为空', trigger: 'blur' }],
        phone: [{ required: true, validator: checkPhone, trigger: 'blur' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
        newPassword: [{ required: true, validator: validatePass, trigger: 'blur' }],
      }
    }
  },
  mounted () {
    this.getTableList()
  },
  methods: {
    //查询
    submitSearch () {
      this.getTableList()
    },
    //新增列表
    addList () {
      this.ruleType = 1
      this.dialogFormVisible = true
      // this.$refs['admin-details'].init('add')
    },
    //导出
    exportExcel () { },
    //获取表格数据
    getTableList () {
      this.tabLoading = true
      this.$http.get('/userInfo/findUserInfoList', {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          userName: this.retrieveForm.userName,
          email: this.retrieveForm.email,
          phone: this.retrieveForm.phone,
        }
      }).then(({ data: result }) => {
        this.tabLoading = false
        let list = result.data.list
        this.tableData = list.filter(ele => ele.userName != 'admin')
        this.pageInfo.total = result.data.total
      })
    },
    headerRowClick (row) { },
    //编辑数据
    editList (row) {
      this.ruleType = 2
      this.ruleForm = {
        id: row.id,
        userName: row.userName,
        phone: row.phone,
        email: row.email,
      }
      this.dialogFormVisible = true
      // this.$refs['admin-details'].init('edit', row)
    },
    //修改密码
    editPassword (row) {
      this.ruleType = 3
      this.ruleForm = {
        id: row.id,
        password: '',
        newPassword: '',
      }
      this.dialogFormVisible = true
    },
    //删除用户
    deleteList (row) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.delete(`/userInfo?id=${row.id}`).then(res => {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getTableList()
        })
      }).catch(_ => { })
    },
    //新增编辑账户
    AddEditRole () {
      this.$refs['ruleForm'].validate((valid) => {
        if (!valid) return
        this.butLoading = true
        let apiUrl = ''
        if (this.ruleType == 1) {
          apiUrl = '/userInfo/addUserInfo'
        } else if (this.ruleType == 2) {
          apiUrl = '/userInfo/editUserInfo'
        } else {
          apiUrl = '/userInfo/editPasswordByid'
        }
        this.$http.post(apiUrl, this.ruleForm).then(res => {
          this.$message({
            message: this.ruleForm.id ? '编辑成功' : '新增成功',
            type: 'success'
          })
          this.getTableList()
          this.butLoading = false
          this.dialogFormVisible = false
        })
      })
    },
    //分配角色
    allocation (row) {
      this.dialogRole = true
      this.userId = row.id
      if (row.roles) {
        this.roleIds = row.roles.map(ele => { return ele.id })
      } else {
        this.roleIds = []
      }
      this.$http.get('/role/findRoleList', { params: { type: 'list' } }).then(({ data: result }) => {
        this.roleData = result.data
      })
    },
    //重置清除校验
    resetForm () {
      Object.assign(this.ruleForm, this.$options.data().ruleForm)
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate() //清除校验
      })
    },
    //全选
    handleCheckAllChange (val) {
      this.roleIds = val ? this.roleData.map(res => { return res.id }) : []
      this.isIndeterminate = false
    },
    //单选
    handleCheckedCitiesChange (value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.roleData.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.roleData.length
    },
    //分配角色
    userRole () {
      this.butLoading = true
      this.$http.post('/role/addSysRoleUserBatch', {
        roleId: this.roleIds.join(','),
        userId: this.userId,
      }).then(res => {
        this.$message({
          message: '分配成功',
          type: 'success'
        })
        this.getTableList()
        this.butLoading = false
        this.dialogRole = false
      })
    }
  },
}
</script>

<style lang="less" scoped>
</style>